import React, { FC } from 'react';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';

import { GalleryDataType, ID } from '../../../providers/TypeProvider';

import Close from '../../../icons/Close.svg';

interface GalleryPopupProps {
    onClick: React.MouseEventHandler<HTMLDivElement>,
    data: GalleryDataType,
    ID: ID<number>
}
 
const GalleryPopup: FC<GalleryPopupProps> = (props: GalleryPopupProps) => {
    const modal = props.data[props.ID ?? 0];

    const img = getImage(modal.image);

    return ( 
        <div className="gallery__popup">
            <div className="gallery__popup-background" onClick={ props.onClick }>
                <img className="gallery__popup-close-icon" src={ Close } onClick={ props.onClick } />
                    <div>
                        <GatsbyImage
                            className="gallery__popup-img"
                            image={ img as IGatsbyImageData }
                            alt={ modal.alt as string }
                        />
                    </div>
            </div>
        </div>
    );
}
 
export default GalleryPopup;