import React, { FC } from 'react';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';

import Button, { ButtonType, LinkType } from '../Common/Button';
import Header, { HeaderType } from '../Common/Header';
import Paragraph, { ParagraphType, TextType } from '../Common/Paragraph';

import { ServicesSectionDataType } from '../../../providers/TypeProvider';

interface ServicesTileProps {
    onClick: React.MouseEventHandler<HTMLDivElement>,
    image: IGatsbyImageData,
    alt: string,
    title: string,
    description: string,
    sectionData: ServicesSectionDataType
}
 
const ServicesTile: FC<ServicesTileProps> = (props: ServicesTileProps) => (
    <div className="col-24-xs col-8-lg">
        <div className="services__tile" onClick={ props.onClick }>
            <GatsbyImage
                className="services__tile-img"
                image={ props.image }
                alt={ props.alt }
            />
            <Header
                type={ HeaderType.H3 }
                text={ props.title }
            />
            <Paragraph
                type={ ParagraphType.SMALL }
                textType={ TextType.STRING }
                text={ props.description }
            />
            <Button
                text={ props.sectionData.buttonText }
                type={ ButtonType.TERTIARY_UNDERLINE }
                linkType={ LinkType.INTERNAL }
            />
        </div>
    </div>
);

export default ServicesTile;