import React from 'react';
import { graphql, PageProps } from 'gatsby';

import PageBundler from '../components/Sections/PageBundler';

import { 
    HeroDataType, 
    AboutDataType, 
    ServicesDataType, 
    ServicesSectionDataType, 
    VoucherDataType, 
    GalleryDataType, 
    GallerySectionDataType,
    ContactUsDataType } from '../providers/TypeProvider';

const IndexPage = (props: PageProps) => {
    const query: any = props.data;
    const heroQuery = query.prismicHeroSection;
    const aboutQuery = query.prismicAboutSection;
    const servicesSectionQuery = query.prismicServicesSection;
    const servicesQuery = query.allPrismicService;
    const voucherQuery = query.prismicVoucherSection;
    const galleryQuery = query.prismicGallerySection;
    const contactUsQuery = query.prismicContactUsSection;

    const heroData: HeroDataType = {
        headline1: heroQuery.data.header_headline_1,
        headline2: heroQuery.data.header_headline_2,
        tagline: heroQuery.data.header_2,
        primaryButton: heroQuery.data.primary_button,
        secondaryButton: heroQuery.data.secondary_button,
        buttonLink: heroQuery.data.button_link.url
    }
    
    const aboutData: AboutDataType = {
        image: aboutQuery.data.image,
        alt: aboutQuery.data.image.alt,
        header: aboutQuery.data.headline,
        paragraphs: aboutQuery.data.paragraphs.map((i: any) => i.paragraph)
    }

    const servicesSectionData: ServicesSectionDataType = {
        header: servicesSectionQuery.data.headline,
        tagline: servicesSectionQuery.data.tagline,
        buttonText: servicesSectionQuery.data.button_text,
        getCouponsText: servicesSectionQuery.data.get_coupons_text
    }

    const servicesData: ServicesDataType = servicesQuery.nodes.map((i: any) => {
        return {
            image: i.data.image,
            alt: i.data.image.alt,
            title: i.data.service_name,
            description: i.data.tagline,
            paragraphs: i.data.body.map((z: any) => {
                return {
                    text: z.primary.text,
                    isList: z.primary.islistitem
                }
            }),
            link: i.data.coupon_link.url
        }
    });

    const voucherData: VoucherDataType = {
        header: voucherQuery.data.headline,
        tagline: voucherQuery.data.tagline,
        buttonText: voucherQuery.data.button_text,
        buttonLink: voucherQuery.data.button_link.url
    }

    const gallerySectionData: GallerySectionDataType = {
        header: galleryQuery.data.headline
    }

    const galleryData: GalleryDataType = galleryQuery.data.images.map((i: any) => {
        return {
            image: i.image,
            alt: i.image.alt
        }
    });

    const contactUsData: ContactUsDataType = {
        header: contactUsQuery.data.headline,
        tagline: contactUsQuery.data.tagline,
        sideHeader: contactUsQuery.data.side_header,
        buttonText: contactUsQuery.data.button_text
    }

  return (
    <PageBundler 
        heroData={ heroData }
        aboutData={ aboutData }
        servicesData={ servicesData }
        servicesSectionData={ servicesSectionData }
        voucherData={ voucherData }
        gallerySectionData={ gallerySectionData }
        galleryData={ galleryData }
        contactUsData={ contactUsData }
    />
  )
}

export const dataQuery = graphql`
    query IndexQuery {
    prismicHeroSection {
        data {
            header_headline_1
            header_headline_2
            header_2
            primary_button
            secondary_button
            button_link {
                url
            }
        }
    }
    prismicAboutSection {
        data {
            headline
            image {
                alt
                gatsbyImageData(placeholder: BLURRED)
            }
            paragraphs {
                paragraph
            }
        }
    }
    prismicServicesSection {
        data {
            headline
            tagline
            button_text,
            get_coupons_text
        }
    }
    allPrismicService {
        nodes {
            data {
                image {
                    alt
                    gatsbyImageData(placeholder: BLURRED)
                }
                coupon_link {
                    url
                }
                service_name
                tagline
                body {
                    ... on PrismicServiceDataBodyServiceParagraph {
                        id
                        primary {
                            text
                            islistitem
                        }
                    }
                }
            }
        }
    }
    prismicVoucherSection {
        data {
            headline
            tagline
            button_text
            button_link {
                url
            }
        }
    }
    prismicGallerySection {
        data {
            headline
            images {
                image {
                gatsbyImageData(placeholder: BLURRED)
                alt
                }
            }
        }
    }
    prismicContactUsSection {
        data {
            headline
            tagline
            side_header
            button_text
        }
    }
}
`

export default IndexPage;
