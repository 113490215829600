const container = {
    show: {
        transition: {
            staggerChildren: 0.35
        }
    }
}

const item = {
    hidden: {
        opacity: 0,
        y: 200
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [.6, .01, -.05, .95],
            duration: 1
        }
    },
    exit: {
        opacity: 0,
        y: -200,
        transition: {
            ease: "easeInOut",
            duration: .8
        }
    }
}

export type AnimationType = { initial: any, animate: any, transition: any }

export const simpleFadeIn = (duration: number, delay: number) => ({
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration, delay }
});

export const simpleFadeInAndTranslateHorizontal = (duration: number, delay: number, translation: number) => ({
    initial: { opacity: 0, x: translation },
    animate: { opacity: 1, x: 0 },
    transition: { duration, delay }
});

export const simpleFadeInAndTranslateVertical = (duration: number, delay: number, translation: number) => ({
    initial: { opacity: 0, y: translation },
    animate: { opacity: 1, y: 0 },
    transition: { duration, delay }
})

