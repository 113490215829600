import React, { FC } from 'react';

import Layout from '../Layout/Layout';
import VideoShowcase from './VideoShowcase';
import About from './About';
import Services from './Services';
import Vouchers from './Vouchers';
import Gallery from './Gallery';
import Contact from './Contact';

import { 
    HeroDataType, 
    AboutDataType, 
    ServicesSectionDataType, 
    ServicesDataType, 
    VoucherDataType, 
    GallerySectionDataType, 
    GalleryDataType, 
    ContactUsDataType } from '../../providers/TypeProvider';

interface PageBundlerProps {
    heroData: HeroDataType,
    aboutData: AboutDataType,
    servicesSectionData: ServicesSectionDataType,
    servicesData: ServicesDataType,
    voucherData: VoucherDataType,
    gallerySectionData: GallerySectionDataType,
    galleryData: GalleryDataType,
    contactUsData: ContactUsDataType
}
 
const SectionBundler: FC<PageBundlerProps> = (props: PageBundlerProps) => ( 
    <Layout>
        <VideoShowcase data={ props.heroData } />
        <main>
            <About data={ props.aboutData } />
            <Services data={ props.servicesData } sectionData={ props.servicesSectionData } />
            <Vouchers data={ props.voucherData } />
            <Gallery data={ props.galleryData } sectionData={ props.gallerySectionData } />
            <Contact data={ props.contactUsData } />
        </main>
    </Layout>
);
 
export default SectionBundler;