import React, { FC } from 'react';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';

import Header, { HeaderType } from '../Common/Header';
import Paragraph, { ParagraphType, TextType } from '../Common/Paragraph';
import Button, { ButtonType, LinkType } from '../Common/Button';

import { ServicesSectionDataType, ServicesDataType, ID } from '../../../providers/TypeProvider';

import Close from '../../../icons/Close.svg';

interface ServicesModalProps {
    onClick: React.MouseEventHandler<HTMLDivElement>,
    data: ServicesDataType,
    sectionData: ServicesSectionDataType,
    ID: ID<number>
}

const ServicesModal: FC<ServicesModalProps> = (props: ServicesModalProps) => {
    const modal = props.data[props.ID ?? 0];

    const img = getImage(modal.image);
    
    return ( 
        <div className="services__modal">
            <div className="services__modal-background" onClick={ props.onClick }>
                <div className="services__modal-box">
                    <img className="services__modal-close-icon" src={ Close } onClick={ props.onClick } />
                    <div className="services__modal-content-container">
                        <GatsbyImage
                            className="services__modal-img"
                            image={ img as IGatsbyImageData }
                            alt={ modal.alt }
                        />
                        <div className="services__modal-text-container">
                            <Header
                                type={ HeaderType.H3}
                                text={ modal.title }
                            />
                            <Paragraph
                                type={ ParagraphType.REGULAR }
                                textType={ TextType.STRING }
                                text={ modal.description }
                            />
                            <Paragraph
                                className="body"
                                type={ ParagraphType.REGULAR }
                                textType={ TextType.MODAL }
                                modalParagraphs={ modal.paragraphs as { text: string, isList: boolean }[] }
                            />
                            <Button
                                type={ ButtonType.TERTIARY }
                                linkType={ LinkType.EXTERNAL }
                                text={ props.sectionData.getCouponsText }
                                link={ modal.link }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default ServicesModal;