import React, { FC } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface GalleryTileProps {
    onClick: React.MouseEventHandler<HTMLDivElement>,
    image: IGatsbyImageData,
    alt: string,
}
 
const GalleryTile: FC<GalleryTileProps> = (props: GalleryTileProps) => {
    return ( 
        <div className="col-12-xs col-8-lg">
            <div className="gallery__tile" onClick={ props.onClick }>
                <GatsbyImage 
                    className="gallery__tile-img"
                    image={ props.image as IGatsbyImageData }
                    alt={ props.alt }
                />
            </div>
        </div>
    );
}
 
export default GalleryTile;