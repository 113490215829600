import React, { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Header, { HeaderType } from '../Elements/Common/Header';
import Paragraph, { ParagraphType, TextType } from '../Elements/Common/Paragraph';
import Button, { ButtonType, LinkType } from '../Elements/Common/Button';

import { ButtonLinks } from '../../providers/LinkProvider';
import { VoucherDataType } from '../../providers/TypeProvider';

interface VouchersProps {
    data: VoucherDataType
}
 
const Vouchers: FC<VouchersProps> = (props: VouchersProps) => {
    const alt = "A picture of woman's hand."

    return ( 
        <section className="vouchers">
            <div className="container">
                <div className="row">
                    <StaticImage
                        className="vouchers__img"
                        src="../../images/Vouchers-Image.jpg"
                        alt={ alt }
                        quality={ 100 }
                        height={ 300 }
                        placeholder="blurred"
                        layout="fixed"
                    />
                    <div className="vouchers__text-container">
                        <Header
                            className="white capitalize"
                            type={ HeaderType.H3 }
                            text={ props.data.header }
                        />
                        <Paragraph 
                            className="white"
                            type={ ParagraphType.REGULAR }
                            textType={ TextType.STRING }
                            text={ props.data.tagline }
                        />
                        <Button 
                            type={ ButtonType.TERTIARY }
                            linkType={ LinkType.EXTERNAL }
                            link={ props.data.buttonLink }
                            text={ props.data.buttonText }
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Vouchers;