import React, { FC } from 'react';
import { IGatsbyImageData, getImage } from 'gatsby-plugin-image';

import ServicesTile from './ServicesTile';
import ServicesModal from './ServicesModal';

import { useModal } from '../../../hooks/useModal';
import { ServicesSectionDataType, ServicesDataType } from '../../../providers/TypeProvider';

interface ServicesTilesProps {
    data: ServicesDataType,
    sectionData: ServicesSectionDataType
}
 
const ServicesTiles: FC<ServicesTilesProps> = (props: ServicesTilesProps) => {
    const { layoutID, expander, expand } = useModal();

    return ( 
            <>
                {
                    props.data.map((tile, index) => (
                        <ServicesTile
                            onClick={ () => expander(index) }
                            image={ getImage(tile.image) as IGatsbyImageData }
                            alt={ tile.alt }
                            title={ tile.title }
                            description={ tile.description }
                            key={ index }
                            sectionData={ props.sectionData }
                        />
                    ))
                }
                { expand && 
                    <ServicesModal 
                        onClick={ () => expander(layoutID) } 
                        data={ props.data } 
                        sectionData={ props.sectionData } 
                        ID={ layoutID } 
                    /> 
                }
            </>
    )
}
 
export default ServicesTiles;