import React, { FC, useState, useEffect } from 'react';
import { IGatsbyImageData, getImage } from 'gatsby-plugin-image';

import GalleryTile from '../../Elements/Gallery/GalleryTile';
import GalleryPopup from '../../Elements/Gallery/GalleryPopup';

import { useModal } from '../../../hooks/useModal';
import { GalleryDataType } from '../../../providers/TypeProvider';

interface GalleryTilesProps {
    data: GalleryDataType
}
 
const GalleryTiles: FC<GalleryTilesProps> = (props: GalleryTilesProps) => {
    const { layoutID, expander, expand } = useModal();

    return ( 
            <>
                {
                    props.data.map((tile, index) => (
                        <GalleryTile
                            key={ index }
                            onClick={ () => expander(index) }
                            image={ getImage(tile.image) as IGatsbyImageData }
                            alt={ tile.alt }
                        />
                    ))
                }
                { expand && <GalleryPopup onClick={ () => expander(layoutID) } data={ props.data } ID={ layoutID } /> }
            </>

    );
}
 
export default GalleryTiles;