import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import Button, { ButtonType, LinkType } from '../Elements/Common/Button';
import Header, { HeaderType } from '../Elements/Common/Header';
import Paragraph, { ParagraphType, TextType } from '../Elements/Common/Paragraph';
import InfoBlock from '../Elements/Common/InfoBlock';

import { simpleFadeInAndTranslateHorizontal, simpleFadeIn } from '../../providers/AnimationProvider';
import { schema } from '../../providers/SchemaProvider';
import { ContactUsDataType } from '../../providers/TypeProvider';

interface ContactUsProps {
    data: ContactUsDataType
}

const Contact: FC<ContactUsProps> = (props: ContactUsProps) => {
    const alt = "Image of Lotus Spa Office";

    const [formSubmitted, setFormSubmitted] = useState('');
    const [formStyle, setFormStyle] = useState('');

    const { register, handleSubmit, formState: { errors }, formState } = useForm({ resolver: yupResolver(schema) });

    const submitForm = async (data: any) => {
        try {
            let res = await fetch('https://lotusspa.info/api/submit', {
              method: "POST",
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ name: data.name, email: data.email }),
            });

            if (res.status === 200) {
                setFormSubmitted(() => 'Your message has been sent! We will get back to you shortly.');
                setFormStyle(() => 'green');
            } else {
                setFormSubmitted(() => 'Please fix any errors in the form.');
                setFormStyle(() => 'red');
            }
          } catch (err) {
            setFormSubmitted(() => 'Please fix any errors in the form.');
            setFormStyle(() => 'red');
          }
    };
        
    const textAnimation1 = simpleFadeInAndTranslateHorizontal(.65, 0, -100);
    const textAnimation2 = simpleFadeIn(.65, .5);

    return ( 
        <section className="contact-us" id="contact-us">
            <div className="container">
                <div className="row">
                    <div className="col-xs-24 col-xl-12">
                        <motion.form 
                            className="contact-us__form" 
                            onSubmit={ handleSubmit(submitForm) } 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={ textAnimation1.transition }
                            variants={ { 
                                hidden: textAnimation1.initial,
                                visible: textAnimation1.animate
                            }}>
                            <Header
                                className="capitalize"
                                type={ HeaderType.H3 }
                                text={ props.data.header }
                            />
                            <Paragraph
                                type={ ParagraphType.REGULAR }
                                textType={ TextType.STRING }
                                text={ props.data.tagline }
                            />
                            <div className="contact-us__form-input">
                                <input type="text" { ...register("name") } id="name" required />
                                <label htmlFor="name">Name*</label>
                            </div>
                            <div className="contact-us__form-input">
                                <input type="email" { ...register("email") } id="email" required />
                                <label htmlFor="email">Email*</label>
                            </div>
                            <Button
                                type={ ButtonType.TERTIARY }
                                linkType={ LinkType.CONTACT }
                                text={ props.data.buttonText }
                            />
                            <small style={{ color: formStyle }}>{ formSubmitted }</small>
                        </motion.form>
                    </div>
                    <div className="col-xs-24 col-xl-12">
                        <div className="contact-us__info-container">
                            <StaticImage
                                className="contact-us__info-img"
                                src="../../images/Contact-Image.jpg"
                                alt={ alt }
                                quality={ 100 }
                                height={ 600 }
                                placeholder="blurred"
                            />
                            <motion.div 
                                className="contact-us__info-text" 
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                transition={ textAnimation2.transition }
                                variants={ { 
                                    hidden: textAnimation2.initial,
                                    visible: textAnimation2.animate
                                }}>
                                <Header
                                    className="capitalized white"
                                    type={ HeaderType.H3 }
                                    text={ props.data.sideHeader }
                                />
                                <ul className="contact-us__info-blocks">
                                    <InfoBlock isWhite boxed />
                                </ul>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-us__map">
                <div className="contact-us__map-blockers"></div>
                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe
                            width="600"
                            height="500"
                            id="gmap_canvas"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.0112858462635!2d-117.85638048478921!3d33.889363180650605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcd417d539ca2d%3A0x5db35a5587ff1623!2s601%20E%20Yorba%20Linda%20Blvd%20%235%2C%20Placentia%2C%20CA%2092870!5e0!3m2!1sen!2sus!4v1652498746000!5m2!1sen!2sus"
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"
                            scrolling="no">
                        </iframe>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Contact;