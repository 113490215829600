import React, { FC } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import { ButtonLinks } from '../../../providers/LinkProvider';
 
const Arrow : FC = () => (
    <AnchorLink className="arrow" title="Arrow" to={ ButtonLinks.arrow }>
        <span className="arrow__icon"></span>
        <span className="arrow__icon"></span>
        <span className="arrow__icon"></span>
    </AnchorLink> 
);

export default Arrow;