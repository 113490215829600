import React, { FC, useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import Header, { HeaderType } from '../Elements/Common/Header';
import GalleryTiles from '../Elements/Gallery/GalleryTiles';

import { simpleFadeInAndTranslateHorizontal } from '../../providers/AnimationProvider';
import { GallerySectionDataType, GalleryDataType } from '../../providers/TypeProvider';

interface GalleryProps {
    data: GalleryDataType,
    sectionData: GallerySectionDataType
}
 
const Gallery: FC<GalleryProps> = (props: GalleryProps) => { 
    const textAnimation = simpleFadeInAndTranslateHorizontal(.65, 0, -100);
    
    return ( 
        <section className="gallery" id="gallery">
            <div className="container">
                <div className="row">
                    <div className="col-24-xs col-24-lg">
                        <motion.div 
                            className="services__content-container" 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={ textAnimation.transition }
                            variants={ { 
                                hidden: textAnimation.initial,
                                visible: textAnimation.animate
                            }}>
                            <Header
                                type={ HeaderType.H2 }
                                text={ props.sectionData.header }
                            />
                        </motion.div>
                    </div>
                </div>
                <div className="row">
                    <GalleryTiles data={ props.data } />
                </div>
            </div>
        </section>
    );
}
 
export default Gallery;