import React, { useEffect, useState } from 'react';

import { ID } from '../providers/TypeProvider';

export function useModal() {
    const [expand, setExpand] = useState(false);
    const [layoutID, setLayoutID] = useState<ID<number>>(null);

    const expander = (ID : ID<number>) => {
        if (expand !== true) {
            setExpand(true);
            setLayoutID(ID);
        } else {
            setExpand(false);
            setLayoutID(null);
        }
    };

    useEffect(() => {
        const html = document.querySelector('html');
        
        if (html) {
            html.style.overflow = expand ? 'hidden' : 'auto';
        }

    }, [expand]);

    return { layoutID, expander, expand }
}