import React, { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import Header, { HeaderType } from '../Elements/Common/Header';
import Paragraph, { ParagraphType, TextType } from '../Elements/Common/Paragraph';
import ServicesTiles from '../Elements/Services/ServicesTiles';

import { simpleFadeInAndTranslateHorizontal } from '../../providers/AnimationProvider';
import { ServicesSectionDataType, ServicesDataType } from '../../providers/TypeProvider';

interface ServicesProps {
    data: ServicesDataType,
    sectionData: ServicesSectionDataType
}

 
const Services : FC<ServicesProps> = (props: ServicesProps) => {
    const textAnimation = simpleFadeInAndTranslateHorizontal(.65, 0, -100)

    return (
        <section className="services" id="services">
            <div className="container">
                <div className="row">
                    <div className="col-24-xs col-24-lg">
                        <motion.div 
                            className="services__content-container" 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={ textAnimation.transition }
                            variants={ { 
                                hidden: textAnimation.initial,
                                visible: textAnimation.animate
                            }}>
                            <Header
                                type={ HeaderType.H2 }
                                text={ props.sectionData.header }
                            />
                            <Paragraph
                                className="services__leading"
                                type={ ParagraphType.REGULAR }
                                textType={ TextType.STRING }
                                text={ props.sectionData.tagline } />
                        </motion.div>
                    </div>
                </div>
                <div className="row">
                    <ServicesTiles data={ props.data } sectionData={ props.sectionData } />
                </div>
                <div className="row">
                    <div className="col-24-xs col-12-lg">
                        <motion.div 
                            className="services__before-after-content-container" 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={ textAnimation.transition }
                            variants={ { 
                                hidden: textAnimation.initial,
                                visible: textAnimation.animate
                            }}>
                            <Header
                                type={ HeaderType.H3 }
                                text="Before"
                            />
                            <StaticImage
                                className="services__before-after-img"
                                src="../../images/Before-Image.jpg"
                                alt="Before treatment image"
                                quality={ 100 }
                                height={ 300 }
                                placeholder="blurred"
                            />
                        </motion.div>
                    </div>
                    <div className="col-24-xs col-12-lg">
                        <motion.div 
                            className="services__before-after-content-container" 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={ textAnimation.transition }
                            variants={ { 
                                hidden: textAnimation.initial,
                                visible: textAnimation.animate
                            }}>
                            <Header
                                type={ HeaderType.H3 }
                                text="After"
                            />
                            <StaticImage
                                className="services__before-after-img"
                                src="../../images/After-Image.jpg"
                                alt="After treatment image"
                                quality={ 100 }
                                height={ 300 }
                                placeholder="blurred"
                            />
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Services;